export const MONGO_TABLE_FOLLOW = "follow";
export const MONGO_TRANSFER = "transfer";
export const  MONGO_WEEKLY_CART_MAIL = "weeklyCartMail"


// <<<<<<<<<<<<<<<< Schema of MONGO_TRANSFER >>>>>>>>>>>>>>>>>>  
// {
// tableName: "transfer",
// payload: {
//     txId:"string", // transaction id
//     trId:"string", // transfer id 
//     trTime: "number", // payout transfer time 
//     status: "string" // pending done underDispute
// },
// }

